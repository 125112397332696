<template>
	<v-layout v-if="node || isCompany" v-observe-visibility="visibilityChanged" fill-height align-center justify-center>
		<v-flex v-if="showCounter || loadingCounter" :class="backgroundClass">
			<v-tooltip v-if="!loadingCounter" right>
				<template v-slot:activator="{ on }">
					<span class="counter-format" v-on="on">{{ document ? '' : counter }}</span>
				</template>
				<span v-t="text" />
			</v-tooltip>
		</v-flex>
	</v-layout>
</template>

<script>
import { mapState } from 'vuex'

import DocumentsCounterModuleGuard from '@/mixins/ModulesGuards/Documents/DocumentsCounterModuleGuard'

export default {
	name: 'CounterBadge',
	mixins: [DocumentsCounterModuleGuard],
	props: {
		isCompany: {
			type: Boolean,
			required: false,
			default: false
		},
		value: {
			type: Object,
			required: false,
			default: null
		}
	},
	data: function () {
		return {
			hasBeenVisible: false,
			loadingCounter: false
		}
	},
	computed: {
		...mapState({
			treeStructure: state => state.documents.treeStructure
		}),
		model: {
			get: function () {
				return this.value
			},
			set: function (value) {
				this.$emit('input', value)
			}
		},
		company: function () {
			return this.model && this.isCompany ? this.model : null
		},
		node: function () {
			return this.model && !this.isCompany && (this.model.is_folder || this.model.is_document) ? this.model : null
		},
		document: function () {
			return this.node && this.node.is_document ? this.node : null
		},
		treeStructureCounter: function () {
			return this.treeStructure ? this.treeStructure.counter : 0
		},
		notifications: {
			get: function () {
				return this.company?.notifications ?? 0
			},
			set: function (value) {
				if (value === null || value === undefined || isNaN(Number(value))) {
					return
				}
				this.company.notifications = value
				this.$emit('update')
			}
		},
		nodeCounter: function () {
			let result = 0
			if (this.node != null && !this.node.is_trash && this.node.counter != null) {
				result = this.node.counter
			}
			return result
		},
		companyCounter: function () {
			if (!this.company) {
				return 0
			}
			if (this.company.id !== this.vendorId) {
				return this.notifications
			}
			return this.treeStructureCounter && this.treeStructureCounter > 0 ? this.treeStructureCounter : this.notifications
		},
		counter: function () {
			return this.node ? this.nodeCounter : this.companyCounter
		},
		showCounter: function () {
			return this.counter > 0
		},
		text: function () {
			return this.document ? this.$t('documents.new') : this.$tc('documents.new_docs', this.counter, { counter: this.counter })
		},
		backgroundClass: function () {
			let result
			if (this.loadingCounter) {
				result = ''
			} else if (this.document) {
				result = 'red counter-background counter-background-document'
			} else {
				result = 'red counter-background counter-background-folder'
			}
			return result
		}
	},
	watch: {
		hasBeenVisible: {
			immediate: true,
			handler: function (newHasBeenVisible) {
				if (newHasBeenVisible) {
					this.loadCounter()
				}
			}
		},
		treeStructureCounter: {
			handler: function () {
				if (this.company?.id == this.vendorId) {
					this.notifications = this.treeStructureCounter
				}
			}
		}
	},
	methods: {
		getModuleEventsActionsMapping: function () {
			return [
				{ event: this.events.RESETING_COUNTER, action: this.resetingCounter },
				{ event: this.events.RESETING_COUNTER_ENDED, action: this.stopLoading },
				{ event: this.events.RESET_COUNTER, action: this.removeNotification },
				{ event: this.events.DOCUMENT_OPENED, action: this.removeNotification },
				{ event: this.events.DOCUMENT_DOWNLOADED, action: this.removeNotification },
				{ event: this.events.DOCUMENT_DELETED, action: this.removeNotification }
			]
		},
		loadCounter: function () {
			if (this.node != null && !this.node.is_trash && this.node.counter == null) {
				this.loadingCounter = true
				this.service.loadNodeCounter(this.accountingFirmId, this.vendorId, this.node).finally(() => {
					this.loadingCounter = false
				})
			}
		},
		visibilityChanged: function (isVisible) {
			if (!this.hasBeenVisible && isVisible) {
				this.hasBeenVisible = isVisible
			}
		},
		resetingCounter: function (node) {
			if (this.node?.id == node?.id || (this.node === null && node?._is_root)) {
				this.loadingCounter = true
			}
		},
		stopLoading: function (document) {
			if (this.document?.id == document?.id) {
				this.loadingCounter = false
			}
		},
		removeNotification: function (node) {
			if (this.node?.id == node?.id || (this.node === null && node?._is_root)) {
				this.loadingCounter = false
				node.resetcounter()
			}
		}
	}
}
</script>
<style scoped>
.counter-background {
	position: relative;
	border-radius: 50%;
	color: white;
	aspect-ratio: 1;
	text-align: center;
	vertical-align: middle;
}
.counter-background-folder {
	height: 100%;
	width: 100%;
	padding: 1px;
}
.counter-background-document {
	max-height: 10px;
	max-width: 10px;
	height: 10px;
	width: 10px;
	padding: 0px;
}
.counter-format {
	position: absolute;
	text-align: center;
	vertical-align: middle;
	font-size: 0.9rem;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
}
</style>
